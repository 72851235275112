import React, { Component } from "react"
// import classNames from "classnames";
import { OutboundLink } from "gatsby-plugin-google-analytics"

import "../scss/irishse.scss"
import Layout from "../components/layout"
import SEO from "../components/seo"
// import HubspotForm from 'react-hubspot-form';
import { navbarUpdate, setDefaultHeaderTheme } from "../utils/common-util"
import { getHSEOptions, getProtections } from "../data/irishse"
import IRISHSEImg from "../components/iris-hse-img"

var scroll = 0

const videoWHRatio = 1.777

class IRISHSEPage extends Component {
  constructor(props) {
    super(props)

    this.protectionVideos = {}
    this.state = {
      currentScroll: 0,
      // formOpened: false
    }

    // this.toggleForm = this.toggleForm.bind(this);
    // this.onFormSubmitted = this.onFormSubmitted.bind(this);
    this.onPageScroll = this.onPageScroll.bind(this)
    this.getProtDetailsRow = this.getProtDetailsRow.bind(this)
    this.scrollToSection = this.scrollToSection.bind(this)
  }

  componentDidMount() {
    setDefaultHeaderTheme(true)
    navbarUpdate("nav-products", "nav-iris")
    this.setState({
      currentScroll: window.scrollY,
      isMobile: window.innerWidth < 769,
    })
    for (const key in this.protectionVideos) {
      if (
        this.protectionVideos.hasOwnProperty(key) &&
        this.protectionVideos[key]
      ) {
        const $wrap = this.protectionVideos[key]
        let height = $wrap.clientWidth / videoWHRatio
        $wrap.firstElementChild.style.height = height - 1 + "px"
      }
    }
    window.addEventListener("scroll", this.onPageScroll, false)
  }

  onPageScroll = e => {
    let diff = window.scrollY - this.state.currentScroll
    scroll += diff
    if (scroll > 100) {
      scroll = 0
      for (const key in this.protectionVideos) {
        try {
          if (
            this.protectionVideos.hasOwnProperty(key) &&
            this.protectionVideos[key]
          ) {
            const $video = this.protectionVideos[key].firstElementChild
            const bounding = $video.getBoundingClientRect()
            if (
              bounding.top >= 0 &&
              bounding.left >= 0 &&
              bounding.right <=
                (window.innerWidth || document.documentElement.clientWidth) &&
              bounding.bottom <=
                (window.innerHeight || document.documentElement.clientHeight)
            ) {
              $video.paused && $video.play()
            } else if (!$video.paused) {
              $video.pause()
            }
          }
        } catch (error) {
          continue
        }
      }
    }
  }

  scrollToSection(key, e) {
    let $html = document.getElementsByTagName("HTML")[0]
    if (!$html.classList.contains("scroll-smooth")) {
      $html.classList.add("scroll-smooth")
    }
    document.getElementById(key).scrollIntoView({ block: "center" })
    setTimeout(() => {
      $html.classList.remove("scroll-smooth")
    }, 300)
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.onPageScroll, false)
  }

  getProtDetailsRow = prot => {
    return (
      <div
        id={prot.key + "-row"}
        key={prot.key}
        className={"ihpdc-row" + (prot.swipe ? " ihpdc-swipe" : "")}
      >
        <div className="ihpdc-title">{prot.title}</div>
        <div className="ihpdc-desc">{prot.detText}</div>
        <div className="ihpdc-3sec-cont">
          <div className="ihpdc-points">
            <div className="ihpdc-points-head">How does IRIS help?</div>
            <ul>
              {prot.points.map((pt, i) => {
                return (
                  <li key={prot.key + "-point-" + i} className="ihpdc-point">
                    {pt}
                  </li>
                )
              })}
            </ul>
          </div>
          <div className="ihpdc-gap"></div>
          <div
            className="ihpdc-video"
            ref={r => (this.protectionVideos[prot.key] = prot.video ? r : null)}
          >
            <video
              loop
              preload="auto"
              autoPlay
              muted
              crossOrigin="anonymous"
              poster={prot.poster}
            >
              {prot.video ? <source src={prot.video} type="video/mp4" /> : null}
              Your browser does not support the video tag...
            </video>
          </div>
        </div>
      </div>
    )
  }

  // toggleForm = () => {
  //     this.setState({
  //         formOpened: !(this.state.formOpened)
  //     });
  // };

  // onFormSubmitted = () => {
  //     setTimeout(this.toggleForm, 3000);
  // }

  render() {
    const options = getHSEOptions()
    const protections = getProtections()

    return (
      <Layout>
        <SEO title="IRIS for HSE" />
        <div className="irishse-top-content">
          <h1 className="top-heading lr-padding">
            Health and Safety Use Cases using IRIS AI Computer Vision
          </h1>
          <div className="top-desc lr-padding">
            IRIS is a Computer Vision platform that aims to accomplish zero
            accidents at workplaces. It uses artificial intelligence to create a
            virtual map that analyses the total operational area and configures
            alarms for every hazard using live cctv camera feeds. Real-time
            notifications on WhatsApp, sms, buzzers or PA system announcements
            are raised every time there is a non-compliance. Dashboards
            providing insights can be accessed on app or on web.
          </div>
          <OutboundLink
            className="button is-primary is-rounded hse-web-link"
            href="https://healthandsafety.ai"
            rel="noopener noreferrer"
            target="_blank"
          >
            <span>Explore More</span>
            <span className="icon">
              <i className="fas fa-external-link-alt"></i>
            </span>
          </OutboundLink>
          <div className="hse-opt-wrap">
            <div className="hse-options lr-padding">
              {options.map(opt => (
                <div key={opt.key} className="hse-option">
                  <span className={"hse-opt-icn " + opt.key}></span>
                  <div className="opt-head">{opt.head}</div>
                </div>
              ))}
            </div>
          </div>
        </div>
        <div className="iris-protections-content iww-container">
          <div className="prot-heading">
            How IRIS Protects Your Employees From Key Risks
          </div>
          <div className="protections">
            {protections.map(prot => {
              return (
                <div key={prot.key} className="protection">
                  <IRISHSEImg name={prot.key}></IRISHSEImg>
                  <div className="prot-title">{prot.title}</div>
                  <div className="prot-desc">{prot.text}</div>
                </div>
              )
            })}
          </div>
        </div>
        <div className="iris-hse-prot-details-content iww-container">
          {protections.map(this.getProtDetailsRow)}
        </div>
        {/* <div className={classNames("webinar-banner-wrap", {
                    "wb-closed": this.state.formOpened
                })}>
                    <div className="wb-tag">
                        <div className="wb-text">
                            WEBINAR
                            <br/>
                            11/12/2020
                        </div>
                        <button className="button is-warning is-outlined is-rounded is-small" onClick={this.toggleForm}>
                            <span className="icon is-small">
                                <i className="fas fa-user-plus"></i>
                            </span>
                            <span>Register</span>
                        </button>
                    </div>
                </div>
                {this.state.formOpened ? <div className="wb-reg-form">
                    <HubspotForm
                        portalId='7797396'
                        formId='1f9dece9-dd08-4def-8f0e-b9f554e823ef'
                        onFormSubmitted={this.onFormSubmitted}
                        onFormSubmit={() => {}}
                        loading={<div>Please wait...</div>}
                    />
                    <button className="button is-small close-rf-btn" onClick={this.toggleForm}>
                        <span className="icon">
                            <i className="fas fa-times fa-lg"></i>
                        </span>
                    </button>
                </div> : null} */}
      </Layout>
    )
  }
}

export default IRISHSEPage
