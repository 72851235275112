import forklift from "../video/iris-hse-prot/forklift.mp4"
// import hazardous from '../video/iris-hse-prot/hazardous.mp4';
import fireThermal from "../video/iris-hse-prot/fire_thermal.mp4"
import lifting from "../video/iris-hse-prot/lifting.mp4"
import macGuarding from "../video/iris-hse-prot/mac_guarding.mp4"
import manualHandling from "../video/iris-hse-prot/manual_handling.mp4"
import waHeight from "../video/iris-hse-prot/wa_height.mp4"

import forklift_pstr from "../images/iris-hse-poster/forklift.png"
import fireThermal_pstr from "../images/iris-hse-poster/fire_thermal.png"
import hazardous_pstr from "../images/iris-hse-poster/hazardous.jpg"
import lifting_pstr from "../images/iris-hse-poster/lifting.png"
import macGuarding_pstr from "../images/iris-hse-poster/mac_guarding.png"
import manualHandling_pstr from "../images/iris-hse-poster/manual_handling.png"
import waHeight_pstr from "../images/iris-hse-poster/wa_height.png"

const options = [
  {
    key: "opt-ari",
    head: "Automated Risk Identification",
  },
  {
    key: "opt-rtn",
    head: "Real-time Notification",
  },
  {
    key: "opt-load",
    head: "Live Object and Action Detection",
  },
]

const protections = [
  {
    key: "forklift",
    title: "MHE Safety",
    text: `
        Defining separate pathways for pedestrians and forklifts to avoid accidents.
    `,
    detText: `
        Forklift and pedestrian related risks are identified as one of the major concerns for manufacturing  premises, warehouses, yards depots etc.. IRIS helps by raising immediate alarms to prevent accidents and provides a heat map of violations that help enterprises to identify hazard zones and take steps to rectify them.
        
        Using computer vision, IRIS’ AI algorithm helps observe forklift movement in the defined area. Alarms are raised for incidents and accidents like speeding, wrong direction movements, parking in the movement area, pedestrians not using side walks and more of such non-compliances that even for a short duration of time can lead to major accidents. Dashboards are accessible on apps and on web, providing insights over a period of time to help remove bottlenecks and create a better plan for training and process improvement.    
    `,
    points: [
      "IRIS can detect forklifts (loaded/unloaded), employees, and PPE compliance inside a facility.",
      "Virtual boundaries are made to separate the pathways for forklifts and pedestrians.",
      "Real-time alarms are raised if the defined pathways are not followed.",
      "Near-misses are identified based on rules defined around the cases.",
      "Overall Forklift and Pedestrians compliance rating is generated daily to improve SOPs and training.",
    ],
    swipe: true,
    video: forklift,
    poster: forklift_pstr,
  },
  {
    key: "hazardous",
    title: "Control of Hazardous Energy",
    text: `
        Proper training, use of PPE, and caution signs visibility. Process understanding and improvement of procedures.
    `,
    detText: `
        The practices and procedures necessary to disable machinery or equipment, thereby preventing the release of hazardous energy while employees perform servicing and maintenance activities. The standard outlines measures for controlling hazardous energies—electrical, mechanical, hydraulic, pneumatic, chemical, thermal, and other energy sources.

        Service and maintenance employees play an important role in the smooth and efficient functioning of a company. Risk related to hazardous energy are controlled by training the employees to monitor the lockout processes, ensure clearly visible hazard signs, and ensure following of proper procedure.
    `,
    points: [
      "IRIS monitors the lockout procedure.",
      "Ensures that hazard signs are clearly visible at all times and proper procedure is followed.",
      "Raises alarms when someone is entering hazardous zones without compliant PPE and provide a real-time warning to employee.",
    ],
    swipe: false,
    video: null,
    poster: hazardous_pstr,
  },
  {
    key: "wa_height",
    title: "Work at Height",
    text: `
        Structural integrity, falling objects, overloading, are a few of the major concerns. Trained employees should be assigned such task.
    `,
    detText: `
        'Work at height' includes all those places falling from which can cause injury. Some examples are: working on trestles, flat roof, ladder, ground level adjacent to an excavation, formwork within an excavation, near or adjacent to fragile materials, and erecting false work or formwork.

        Some of the concerns while working on heights include monitoring load on an elevated platform, identify falling objects, use of correct equipment, PPE, manage exclusion zones and lighting.
    `,
    points: [
      "Monitors load on an elevated platform.",
      "Detection of PPE and correct equipment usage.",
      "Detection of over-crowding on scaffolding and falling objects.",
      "Monitoring entry into exclusion zone.",
      "Ensures proper lighting, prevents blind spots.",
    ],
    swipe: true,
    video: waHeight,
    poster: waHeight_pstr,
  },
  {
    key: "manual_handling",
    title: "Manual Handling",
    text: `
        Manual work in facilities such as lifting or moving heavy objects, repetitive or sustained activity can lead to MSDs.
    `,
    detText: `
        Hazardous manual handling occurs when physical force is exerted by a person to lift, lower, push, pull, carry or otherwise move, hold or restrain a load in workplace. Musculoskeletal disorder (MSD) occurs suddenly or over a prolonged time. Injuries can also occur due to a combination of both these mechanisms, for example body tissue that has been weakened by cumulative wear and tear may be vulnerable to sudden damage from a strenuous task. MSD causes a major strain on employees over a period of time and adds to the LTIR (Lost time injury rate) for a company.

        Identification of hazardous manual handling is highly dependent on monitoring by managers on the shop floor. The employees are not able to identify hazardous manual handling in run time.
    `,
    points: [
      "IRIS detects pose of employees in the defined areas where manual handling needs to be monitored.",
      "Compliant pose for manual handling work is defined and monitored.",
      "Daily report is generated for the workers on the shop floor for different shifts.",
      "Improves training procedures to ensure healthier workforce in the long run.",
    ],
    swipe: false,
    video: manualHandling,
    poster: manualHandling_pstr,
  },
  {
    key: "lifting",
    title: "Use of Lifting Equipment",
    text: `
        Key goal is to ensure the equipment's integrity, place where it is used and purpose for which it is used.
    `,
    detText: `
        Lifting equipment's initial integrity, place where it will be used, and the purpose for which it will be used need to be monitored to ensure employee safety.

        IRIS monitors the type of equipment which can raise or lower loads. It covers a broad range of lifting equipments such as cranes, lift trucks and telescopic handlers, high lift pallet trucks - both manual and powered that have the ability to raise the forks above 300mm, goods lifts or passenger lifts, simple systems such as rope and pulley used to raise a bucket of cement on a building site, a construction site hoist, and more.
    `,
    points: [
      "Detection of the type of lifting equipment used in a specific region.",
      "Identification of different types of loads inside a premises.",
      "Monitoring of the type of work the lifting equipment is used for.",
      "Warnings for employees walking/standing under suspended load.",
    ],
    swipe: true,
    video: lifting,
    poster: lifting_pstr,
  },
  {
    key: "fire_thermal",
    title: "Fire and Thermal",
    text: `
        Identification of potential causes of fire. Early detection and speedy extinguishment is the primary concern.
    `,
    detText: `
        OSH regulations on fire and thermal compliance depend on smoke and thermal sensors along with the availability of proper fire fighting solutions such are Fire type specific extinguisher, fire hydrants, availability of fire truck, height of the structure and the corresponding pressure needed in fire hydrants to reach the height, fire escape and most importantly signs indicating the path to a safe zone and to the fire extinguishers.

        Traditional fire sensors depend on heat and smoke to detect fire, taking around 3-5 minutes. In this time, fire spreads to a large area and is difficult to combat. IRIS decreases fire detection time by up to 20 times compared to traditional fire detection systems.
    `,
    points: [
      "Fire detection model is trained to detect fire at an early stage within 10-15 seconds of fire and works up to 50 meters for the earliest stage of fire.",
      "Real-time alarms integrated with local buzzers, PA systems, display systems, email, SMS, push notifications and more.",
      "Detects people stuck in different areas in case of a fire outbreak to provide head count and their location in the building.",
    ],
    swipe: false,
    video: fireThermal,
    poster: fireThermal_pstr,
  },
  {
    key: "mac_guarding",
    title: "Machine Guarding",
    text: `
        The use of machine guards to prevent injuries caused due to machine operations, such as fractures, burn, crushing and more.
    `,
    detText: `
        Machine guards are an organisation's first line of defense against injuries caused by machine operation. Each piece of machinery has its own unique mechanical and non-mechanical hazards. Machines can cause a variety of injuries ranging from minor abrasions, burns or cuts to severe injuries such as fractures, lacerations, crushing injuries or even amputation.

        Machine guards protect most of the contact scenarios except in cases of flying debris, machines operated with contact, moving machinery parts, and negligence by humans.
        
        Major ways for avoiding hazards related to machine operations are - Guarding, Protective Devices/Sheaths, Automated feeding/ejection mechanism and appropriate machine location and maintaining distance.    
    `,
    points: [
      "Detection of employees in the hazardous zone and provide real-time warning.",
      "Detection of caution signs on the machine guards.",
      "Warning to operators in case of an employee is present nearby.",
      "Monitoring periodic lubrication of the machines.",
      "Immediate alerts in case of accidents reducing the time to provide medical help.",
    ],
    swipe: true,
    video: macGuarding,
    poster: macGuarding_pstr,
  },
]

export const getHSEOptions = () => options
export const getProtections = () => protections
