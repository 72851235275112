import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

const IRISHSEImg = ({ name, alt }) => {
  const data = useStaticQuery(graphql`
    query {
      forklift: file(relativePath: { eq: "iris-hse/forklift.png" }) {
        childImageSharp {
          fluid(maxWidth: 401) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      hazardous: file(relativePath: { eq: "iris-hse/hazardous.png" }) {
        childImageSharp {
          fluid(maxWidth: 401) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      fire_thermal: file(relativePath: { eq: "iris-hse/fire-thermal.png" }) {
        childImageSharp {
          fluid(maxWidth: 401) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      lifting: file(relativePath: { eq: "iris-hse/lifting.png" }) {
        childImageSharp {
          fluid(maxWidth: 401) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      mac_guarding: file(relativePath: { eq: "iris-hse/mac-guarding.png" }) {
        childImageSharp {
          fluid(maxWidth: 401) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      manual_handling: file(
        relativePath: { eq: "iris-hse/manual-handling.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 401) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      wa_height: file(relativePath: { eq: "iris-hse/wa-height.png" }) {
        childImageSharp {
          fluid(maxWidth: 401) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return data[name] ? (
    <Img
      fluid={data[name].childImageSharp.fluid}
      alt={alt || ""}
      imgStyle={{ objectFit: "contain" }}
    />
  ) : null
}

export default IRISHSEImg
